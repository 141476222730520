import React, { useState, useEffect } from "react";
import "./styled.css";
// qrcode
import Qreader from "react-qr-reader";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// img
import Logo from "../../assets/image/logo-vibra.jpg";
// componentes
import ListQrcode from "./listQrcode";
// lista
import { codeList } from "./codeList";
// service
import { QRCodeApp, getFunctions } from "../../services/login";
import { createEarnedPoints } from "../../services/ranking";

export default function ReadQrcode(props) {
  const { colorBtn, user } = props;
  // leitor
  const [scan, setScan] = useState(false);
  // alertas
  const [qrcodeRead, setQrcodeRead] = useState(false);
  const [error, setError] = useState("");
  // todos qrcode ja lidos
  const [fullQrcodes, setFullQrcodes] = useState(false);
  // modal
  const [listQrcode, setListQrcode] = useState(false);

  async function readScan(e) {
    setScan(false);
    var search = codeList.find((code) => code.id === e);

    if (!search) {
      setError("O QR Code lido não é válido");
      return;
    } else {
      const data = {
        participanteId: user.idPart,
        eventId: user.idEvent,
        functionId: search.id,
        points: 10,
      };

      await QRCodeApp({ data })
        .then((res) => {
          // console.log("ok", res);
          if (res.message === "FUNCTION_YET_RELEASED") {
            setError("Esse QRCode já foi usado!");
          } else if (res.message === "FUNCTION_RELEASED") {
            setQrcodeRead(true);

            const dataRanking = {
              code: search.description,
              positive: true,
              log: "add points vibra",
            };

            createEarnedPoints(dataRanking, user.idEvent, user.idPart)
              .then((response) => {
                // console.log("pontuado", response);
              })
              .catch((errorPoints) => console.log("erro pontos", errorPoints));
          }
        })
        .catch((erro) => console.log("erro", erro));
    }
  }

  function changeScan() {
    // transform: rotateY(90deg);
    const div = document.getElementById("card-id");

    div.style.transform = "rotateY(90deg)";

    setTimeout(() => {
      setScan(!scan);
      div.style.transform = "rotateY(0)";
    }, 400);
  }

  useEffect(() => {
    if (user) {
      getFunctions(user.idPart, user.idEvent)
        .then((res) => {
          var search = res.data.filter((e) => !e.desbloqueado).length;

          if (search === 0) {
            setFullQrcodes(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  return (
    <>
      {qrcodeRead && (
        <SweetAlert
          success
          title="Pontos atribuidos"
          onConfirm={() => {
            setQrcodeRead(false);
          }}
        >
          Pontos atribuídos ao participante!
        </SweetAlert>
      )}

      {error && (
        <SweetAlert
          warning
          title="Alerta"
          onConfirm={() => {
            setError("");
          }}
        >
          {error}
        </SweetAlert>
      )}

      {listQrcode && (
        <ListQrcode isOpen={listQrcode} toggle={() => setListQrcode(false)} />
      )}

      <div className="card-general-qrcode" id="card-id">
        {scan ? (
          <Qreader
            onScan={(e) => {
              if (e && scan) {
                readScan(e);
              }
            }}
            className="qrcode-scan"
          />
        ) : (
          <img
            src={Logo}
            style={{
              width: "100%",
              borderRadius: "50%",
              // padding: 30,
            }}
          />
        )}
      </div>

      {fullQrcodes ? (
        <div>
          <h3 className="qrcode-msg" style={{ textAlign: "center" }}>
            Parabéns, você já leu todos os QRCode's e ganhou todos os pontos!
          </h3>
        </div>
      ) : (
        <>
          {scan ? (
            <a
              className="action-reader close-reader"
              onClick={() => changeScan()}
            >
              Fechar Scan
            </a>
          ) : (
            <>
              <a
                className="action-reader open-reader"
                style={{ backgroundColor: colorBtn, color: "black" }}
                onClick={() => changeScan()}
              >
                Ler QRCode
              </a>

              {/* <a
            className="action-reader open-reader"
            style={{ backgroundColor: colorBtn, color: "black" }}
            onClick={() => setListQrcode(true)}
          >
            Lista de QRCode
          </a> */}
            </>
          )}
        </>
      )}
    </>
  );
}

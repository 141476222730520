import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import ReadQrcode from "./readQrcode";
import InitialQuestions from "./initialQuestions";
// service
import { verifySurveyByUser } from "../../services/question";

export default function QrCodeVibra() {
  // parametros
  const [searchParams] = useSearchParams();
  // usuario
  const [user, setUser] = useState(null);
  // carregamento
  const [loading, setLoading] = useState(true);
  // step
  const [step, setStep] = useState(0);
  // cores
  const colorBg = "#004206";
  const colorBtn = "#ffdd00";

  async function verifyAnswer() {
    await verifySurveyByUser({
      eventId: user.idEvent,
      loginId: user.idPart,
      question:
        "1. Selecione o(s) tipo(s) de transporte que utilizou para se deslocar até o evento.",
    }).then((res) => {
      if (res.message === "QUESTION_ANSWERED") {
        setStep(2);
      } else if (res.message === "QUESTION_AVAILABLE") {
        setStep(1);
      } else {
      }

      setLoading(false);
    });
  }

  useEffect(() => {
    if (window.location.search !== "") {
      const idPart = searchParams.get("idPart");
      const idEvent = searchParams.get("idEvent");

      setUser({ idPart, idEvent });
    }
  }, [searchParams]);

  useEffect(() => {
    if (user) verifyAnswer();
  }, [user]);

  return (
    <div className="div-general-qrcode" style={{ backgroundColor: colorBg }}>
      {loading ? (
        <a className="a-loading" />
      ) : (
        <>
          {step === 1 && <InitialQuestions user={user} setStep={setStep} />}
          {step === 2 && (
            <ReadQrcode colorBtn={colorBtn} setStep={setStep} user={user} />
          )}
        </>
      )}
    </div>
  );
}

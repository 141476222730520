export const codeList = [
  {
    description: "LINHACOMPLETAADITIVADOSQUIZ",
    id: "a402bf66-6dc7-4ff1-abed-e775ea954cb5",
  },
  {
    description: "FROTA+",
    id: "19ccfe13-c0ff-47b1-b6f6-6c8603810f4f",
  },
  {
    description: "PALESTRA20",
    id: "49d075c8-8a4c-4054-9582-206130f4782c",
  },
  {
    description: "BRMANIAEXTRA",
    id: "e1d7a5f8-ce7f-4988-abc0-eae05440c68e",
  },
  {
    description: "ACELERA+Premmia",
    id: "94176075-9499-40fb-88b5-2bebce370d55",
  },
  {
    description: "BRMANIACONTAINERS",
    id: "05551639-2330-43ad-b2f1-854f55d5dad6",
  },
  {
    description: "BRMANIACASADAEXPERIENCIA",
    id: "6e75db6e-6c94-4280-91c9-e69cffc46381",
  },
  {
    description: "LUBRAX+",
    id: "5aadd7f7-de9b-49a8-9981-91d08a7df853",
  },
  {
    description: "PALESTRA3",
    id: "4d0d79f0-9d5d-4d75-b232-0c52dfc2b50c",
  },
  {
    description: "ELETROPOSTO",
    id: "6cc99265-b41b-4d5c-bd64-29151474db4a",
  },
  {
    description: "BRMANIADEGUSTACAOFOOD",
    id: "b4548038-7bac-4709-b4a4-e06d9ac1b1e0",
  },
  {
    description: "PALESTRA4",
    id: "ef40b8e1-1cfa-4482-ad44-439331613500",
  },
  {
    description: "COLAB",
    id: "f93ba09a-8805-4890-9faa-aaca088aa4d2",
  },
  {
    description: "LOGPRIMEDOM",
    id: "32faf332-56e3-4674-baf4-287ab55ad971",
  },
  {
    description: "PALESTRA6",
    id: "99e3529c-a9cd-4005-a9e5-8156bb8d61bb",
  },
  {
    description: "LOGPRIMEINOVACAO",
    id: "0bc698a8-2da3-46e9-99db-31e291417634",
  },
  {
    description: "COMERC",
    id: "e328640c-04cb-4ecb-a41d-b7a117a6b90d",
  },
  {
    description: "PALESTRA7",
    id: "22e28ecd-82d5-452e-8e79-bb2a9151c949",
  },
  {
    description: "PALESTRA5",
    id: "baa14542-9a16-4076-bfa4-90987307f60c",
  },
  {
    description: "LUBRAX",
    id: "7c2180e5-0857-4748-bb70-3c3783413f0f",
  },
  {
    description: "PALESTRA2",
    id: "e007b795-d160-4fec-a847-4f5b55149d8e",
  },
  {
    description: "PALESTRA15",
    id: "b315be25-ef2c-46f7-9a47-7519c8b72205",
  },
  {
    description: "PALESTRA8",
    id: "3d7f58ce-1616-430f-9fc8-ff7dd72bd64c",
  },
  {
    description: "PALESTRA11",
    id: "8c44d452-bcf3-4428-ab65-77b99d5f962c",
  },
  {
    description: "PALESTRA17",
    id: "60461c55-3d12-492d-97d9-9a5692eadd46",
  },
  {
    description: "PALESTRA14",
    id: "36d69287-d3be-47a8-b2fb-f0ddc7eca773",
  },
  {
    description: "PALESTRA10",
    id: "cf1bb9c3-6bf7-44f4-a3d2-19abf587066e",
  },
  {
    description: "LOGPRIMEARENA",
    id: "135c32a7-6456-44f3-ac4a-1030b27a3ceb",
  },
  {
    description: "BRMANIAMANIAABASTECER",
    id: "7316ccc9-782f-4b05-8d9b-53113248dbe6",
  },
  {
    description: "LUBRAXTOPTURBO",
    id: "1eaa7ed1-750b-4e58-b65e-48daa8184ef8",
  },
  {
    description: "SIGABEM",
    id: "b8288d63-70cd-448b-a441-20fc2dee6a9b",
  },
  {
    description: "PALESTRA19",
    id: "1810f5d5-b31c-4817-bd8f-0e88c1c4fcc4",
  },
  {
    description: "PALESTRA12",
    id: "ad0bfb88-115d-452a-b84e-73d12c76ed6e",
  },
  {
    description: "LINHACOMPLETAADITIVADOSCALCULADORA",
    id: "e44615c3-ca55-4ad7-9108-854476c0c569",
  },
  {
    description: "RODOVIAILHAPOSTOGRID",
    id: "26e3ac3b-43f5-4857-9bcf-0cb281d59e79",
  },
  {
    description: "PALESTRA13",
    id: "bd381b7b-7082-4aef-b9aa-cce956553c8a",
  },
  {
    description: "PALESTRA9",
    id: "6e815785-d733-47ef-aece-5aa4512649b0",
  },
  {
    description: "PALESTRA1",
    id: "f0e716fe-c403-4efc-ac3a-67855974a5bc",
  },
  {
    description: "LOGPRIMEAPP",
    id: "e933949e-ed98-4828-964c-6f5d69d22cd3",
  },
  {
    description: "PALESTRA16",
    id: "3c641345-864c-4464-875f-766621d9a292",
  },
  {
    description: "PALESTRA18",
    id: "3f09e144-7719-49e5-b5c9-33f86b32fe59",
  },
  {
    description: "CAUSA",
    id: "74c05530-929f-4f65-8e26-43952e85e30a",
  },
  {
    description: "",
    id: "",
  },
];
